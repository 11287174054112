import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { fonts, weights, container, mediaQueries } from "../styles"
import Layout from "../components/layout"
import FullWidthSection from "../components/FullWidthSection"

const jobList = [
  { route: "general/", label: "募集概要" },
  { route: "system-engineer/", label: "システムエンジニア" },
  { route: "infrastructure-engineer/", label: "インフラ系エンジニア" },
]

export default () => {
  const JobList = styled.ul`
    padding-left: 0;
    margin-bottom: 114px;
    font-family: ${fonts.serif};

    ${mediaQueries.phoneLarge} {
      margin-bottom: 120px;
    }

    li {
      list-style: none;
      margin: 73px 0;
      text-align: center;

      a {
        font-weight: ${weights.thin};
        font-size: 30px;
        text-align: center;
        line-height: 1.3;

        ${mediaQueries.phoneLarge} {
          font-size: 48px;
          line-height: 1.5;
        }
      }
    }
  `
  return (
    <Layout
      headerData={{
        title: "採用情報",
        height: "400px",
        mobileMinHeight: "93vh",
      }}
    >
      <FullWidthSection
        css={css`
          padding-top: 30px;

          ${mediaQueries.phoneLarge} {
            padding-top: 60px;
          }
        `}
      >
        <div css={container.max}>
          <JobList>
            {jobList.map(job => (
              <li key={job.route}>
                <Link to={`/careers/${job.route}`}>{job.label}</Link>
              </li>
            ))}
          </JobList>
        </div>
      </FullWidthSection>
    </Layout>
  )
}
